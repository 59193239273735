'use client'

import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Language } from 'generated/sdk'

export type LocationProviderProps = {
	children: PropTypes.ReactNodeLike
	language: Language
	origin: string
}
type ContextProps = {
	language: Language
	origin: string
}

export const LocationContext = createContext<ContextProps>({
	language: Language.Pt,
	origin: '',
})

export const LocationProvider = ({ children, language, origin }: LocationProviderProps) => {
	const value = useMemo(() => ({ language, origin }), [language, origin])

	return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}

export const useLocationContext = () => useContext(LocationContext)
