import { init, configureScope, captureException, ErrorBoundary as SentryErrorBoundary } from '@sentry/react'
import { CaptureContext } from '@sentry/types'

const COMMIT_SHA = process.env.GITHUB_COMMIT_SHA || process.env.VERCEL_GITHUB_COMMIT_SHA
const SENTRY_TRACE_ENABLE = process.env.NEXT_PUBLIC_SENTRY_TRACE_ENABLE === 'true'

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
	init({
		dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
		release: COMMIT_SHA,
		environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'development',
		debug: process.env.NODE_ENV === 'development',
		enabled: process.env.NODE_ENV !== 'development',
		tracesSampleRate: SENTRY_TRACE_ENABLE ? 0.1 : 0,
	})

	configureScope(scope => {
		scope.setExtra('SSR', typeof window === 'undefined')
	})
}

const notifyError = process.env.NEXT_PUBLIC_SENTRY_DSN
	? (error: any, captureContext?: CaptureContext) => captureException(error, captureContext)
	: (..._args: any[]) => undefined

const ErrorBoundary = process.env.NEXT_PUBLIC_SENTRY_DSN ? SentryErrorBoundary : null

export { ErrorBoundary, notifyError }
