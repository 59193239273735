export const sizes = {
	xs: 0,
	sm: 576,
	md: 768,
	lg: 992,
	xl: 1200,
}

const breakpointUp = (size: number) => `(min-width: ${size}px)`
export const mediaBreakpointUp = (size: number) => `@media ${breakpointUp(size)}`

const breakpointDown = (size: number) => `(max-width: ${size - 1}px)`
const mediaBreakpointDown = (size: number) => `@media ${breakpointDown(size)}`

export const breakpointBetweenAny = (min: number, max: number) => `(min-width: ${min}px) and (max-width: ${max - 1}px)`
export const mediaBreakpointBetweenAny = (min: number, max: number) =>
	`@media (min-width: ${min}px) and (max-width: ${max - 1}px)`

export const breakpoints = {
	xs: `${sizes.xs}px`,
	sm: `${sizes.sm}px`,
	md: `${sizes.md}px`,
	lg: `${sizes.lg}px`,
	xl: `${sizes.xl}px`,
}

export const breakpointUpXs = breakpointUp(sizes.xs)
export const breakpointUpSm = breakpointUp(sizes.sm)
export const breakpointUpMd = breakpointUp(sizes.md)
export const breakpointUpLg = breakpointUp(sizes.lg)
export const breakpointUpXl = breakpointUp(sizes.xl)
export const mediaBreakpointUpXs = mediaBreakpointUp(sizes.xs)
export const mediaBreakpointUpSm = mediaBreakpointUp(sizes.sm)
export const mediaBreakpointUpMd = mediaBreakpointUp(sizes.md)
export const mediaBreakpointUpLg = mediaBreakpointUp(sizes.lg)
export const mediaBreakpointUpXl = mediaBreakpointUp(sizes.xl)

export const breakpointDownXs = breakpointDown(sizes.sm)
export const breakpointDownSm = breakpointDown(sizes.md)
export const breakpointDownMd = breakpointDown(sizes.lg)
export const breakpointDownLg = breakpointDown(sizes.xl)
export const mediaBreakpointDownXs = mediaBreakpointDown(sizes.sm)
export const mediaBreakpointDownSm = mediaBreakpointDown(sizes.md)
export const mediaBreakpointDownMd = mediaBreakpointDown(sizes.lg)
export const mediaBreakpointDownLg = mediaBreakpointDown(sizes.xl)

export const breakpointOnlyXs = breakpointDown(sizes.sm)
export const breakpointOnlySm = breakpointBetweenAny(sizes.sm, sizes.md)
export const breakpointOnlyMd = breakpointBetweenAny(sizes.md, sizes.lg)
export const breakpointOnlyLg = breakpointBetweenAny(sizes.lg, sizes.xl)
export const breakpointOnlyXl = breakpointUp(sizes.xl)
export const mediaBreakpointOnlyXs = mediaBreakpointDown(sizes.sm)
export const mediaBreakpointOnlySm = mediaBreakpointBetweenAny(sizes.sm, sizes.md)
export const mediaBreakpointOnlyMd = mediaBreakpointBetweenAny(sizes.md, sizes.lg)
export const mediaBreakpointOnlyLg = mediaBreakpointBetweenAny(sizes.lg, sizes.xl)
export const mediaBreakpointOnlyXl = mediaBreakpointUp(sizes.xl)

export const breakpointBetween = (min: number, max: number) => breakpointBetweenAny(min, max)
export const mediaBreakpointBetween = (min: number, max: number) => mediaBreakpointBetweenAny(min, max)
